<template>
  <div class="single-project">
    <section class="servicios-table container">
      <div class="servicios-table__inner">
        <h2 class="servicios-table__heading heading">Servicios</h2>
        <div v-if="cargando">
          <v-progress-linear indeterminate />
          <v-skeleton-loader type="table-thead,table-tbody" />
        </div>
        <div v-else>
          <v-text-field
            prepend-inner-icon="search"
            label="Buscar servicio"
            filled
            v-model="search"
            clearable
          />
          <table>
            <thead>
              <tr>
                <th>Servicio</th>
                <th>Precio de 8:00 - 21:00 hrs</th>
                <th>Precio de 21:01 - 7:59 hrs</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(s,i) in serviciosFiltrados"
                :key="i"
              >
                <td>{{s.servicio}}</td>
                <td
                  align="center"
                  v-text="$nF(s.matutino)"
                />
                <td
                  align="center"
                  v-text="$nF(s.nocturno)"
                />
              </tr>
            </tbody>
          </table>
        </div>
        <br><br>
        <!-- <p><strong>* La únidad médica Paseos de Chihuahua solo provee los servicios señalados con un asterisco.</strong> Las otras unidades médicas proveen todos los servicios.</p> -->
        <p>Es requisito obligatorio presentar receta médica para la aplicación de inyecciones</p>
        <p>Los precios listados son únicamente por el servicio, en algunos servicios se puede requerir material adicional el cual será agregado al costo del servicio.</p>
        <br><br>
      </div>
    </section>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "Servicios",
  mixins: [forms],
  data() {
    return {
      search: null,
      servicios: [],
      serviciosFiltrados: [],
    };
  },
  created() {
    this._getThings("servicios", "servicios");
  },
  watch: {
    servicios(a) {
      this.serviciosFiltrados = Object.assign({}, a);
    },
    filter(a) {
      var PATTERN = a;
      var words = ["hello", "hi", "howdy"];
      var value = new RegExp(words.join("|")).test(str);
      filtered = myArray.filter(function (str) {
        return str.includes(PATTERN);
      });
    },
    search(a) {
      if (!a) {
        this.serviciosFiltrados = Object.assign({}, this.servicios);
        return;
      }
      const words = a
        .toLowerCase()
        .replace(/[^a-z0-9]/gi, ".")
        .split(".");
      const regex = new RegExp(words.join("|"));
      this.serviciosFiltrados = this.servicios.filter(function ({ servicio }) {
        return servicio.toLowerCase().match(regex);
      });
    },
  },
};
</script>